import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import Helmet from "react-helmet";

const AccountSpecialist = ({ location: { pathname }, pageContext }) => {
  const title = "Account Specialistr";

  return (
    <Layout
      seo={{
        title: title,
        href: pathname,
        lang: "pl",
      }}
    >
      <Helmet>
        <script
          async
          src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js"
        ></script>
      </Helmet>
      <section className="page_recrutation">
        <h1>Kariera</h1>
        <div className="row">
          <div className="col-md-3">
            <ul className="categories">
              <li className="cat-item active">
                <Link to="/kariera/account-specialist">Account Specialist</Link>
              </li>
              {/* <li className="cat-item">
                <Link to="/kariera/strategy-planner">Strategy Planner</Link>
              </li> */}
              <li className="cat-item">
                <Link to="/kariera/web-designer">Web Designer</Link>
              </li>
              <li className="cat-item">
                <Link to="/kariera/react-developer">React.js Developer</Link>
              </li>
              <li className="cat-item">
                <Link to="/kariera/php-developer">PHP Developer</Link>
              </li>
              <li className="cat-item">
                <Link to="/kariera/python-developer">
                  Python & Django developer
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-8 offset-md-1">
            <div className="panel-description">
              <p>
                Miejsce pracy: <strong>Kraków</strong>
              </p>
              <p>
                ADream to grupa spółek (naszych własnych) z kluczowych obszarów
                marketingu. Tworzymy nowe brandy i wprowadzamy je na rynek,
                odświeżamy i rozwijamy również te już istniejące. Nasze główne
                obszary działania to; strategia, identyfikacja wizualna,
                opakowania i etykiety, serwisy i aplikacje internetowe, serwisy
                ecommerce, kampanie wizerunkowe i sprzedażowe, social media - to
                tak w skrócie:) Współpracujemy z Klientami z Polski i z
                zagranicy.
              </p>
              <h2>Opis stanowiska:</h2>
              <p>
                Poszukujemy opiekuna klienta posiadającego doświadczenie w pracy
                przy projektach marketingowych. Musisz wiedzieć zarówno czym
                jest Key Visual, o co chodzi z tym całym RWD oraz czym są leady.
                Oczywiście przekrojowo, tak abyś mógł swobodnie wdrażać się w
                szczegóły i poszerzać horyzonty (poszukujemy osoby chcącej nadal
                się rozwijać). Niemniej jeżeli posiadasz doświadczenie w
                koordynowaniu projektów IT (strony/sklepy), których realizujemy
                bardzo dużo to będzie to niewątpliwy atut Twojej kandydatury.
              </p>
              <p>
                Jeżeli uważasz, że DEADLINE to nie stres a motywacja, a
                MULTITASKING to nie chaos a wachlarz możliwości to szanse na
                naszą współpracę znacząco wzrastają:)
              </p>
              <h2>W ADream będziesz odpowiadał za:</h2>
              <ul>
                <li>briefowanie</li>
                <li>przygotowywanie ofert</li>
                <li>
                  utrzymywanie oraz rozwijanie relacji biznesowych z Klientami
                </li>
                <li>koordynację projektów</li>
              </ul>
              <h2>Oczekujemy:</h2>
              <ul>
                <li>
                  doświadczenia w pracy w agencji reklamowej, marketingowej lub
                  w dziale marketingu współpracującym z agencjami
                </li>
                <li>
                  ogólnej wiedzy marketingowej (mile widziane doświadczenie w
                  koordynowaniu projektów IT; strony/sklepy)
                </li>
                <li>
                  wysokiej kultury osobistej i umiejętności komunikacyjnych
                </li>
                <li>skrupulatności oraz poczucia odpowiedzialności</li>
                <li>inicjatywy i pozytywnego nastawienia</li>
              </ul>
              <h2>Oferujemy:</h2>
              <ul>
                <li>pracę w klimatyzowanym biurze w centrum Krakowa</li>
                <li>
                  różnorodność projektów (od średnich po duże, od kreatywnych po
                  te uczące cierpliwości ;)
                </li>
                <li>
                  roczny budżet na udział w szkoleniach lub eventach branżowych
                </li>
                <li>formę współpracy dopasowaną do potrzeb (umowa/b2b)</li>
                <li>kawę z ekspresu bez limitu</li>
                <li>świeże owoce</li>
                <li>coroczne wyjazdy integracyjne</li>
                <li>fajny team!</li>
              </ul>
              <h2>Wymagane informacje i dokumenty:</h2>
              <p>
                Najbardziej interesują nas Twoje doświadczenia i umiejętności,
                dlatego prześlij nam opis (zakres nie szczegóły) swoich
                projektów, średnie Twoje obroty miesięczne, abyśmy wiedzieli w
                jakiej skali projektów masz doświadczenie.
              </p>
              <iframe
                id="account-specialist-form"
                title="Recrutation form - Acount Specialist"
                class="clickup-embed clickup-dynamic-height"
                src="https://forms.clickup.com/4615748/f/4cvj4-5301/U3GOJPOR5UAL08V41K"
                onwheel=""
                width="100%"
                height="100%"
                style={{ border: "none" }}
              ></iframe>
              <p className="page_recrutation_signature">
                Administratorem Danych Osobowych jest Adream sp. z o.o. z
                siedzibą w Krakowie, ul. Chopina 18/3a, KRS: 0000517132. Podanie
                danych osobowych w CV jest dobrowolne, lecz konieczne do
                przeprowadzenia procesu rekrutacyjnego. Konsekwencją niepodania
                danych osobowych będzie brak możliwości przeprowadzenia
                postępowania rekrutacyjnego. Dane osobowe przetwarzane będą na
                podstawie art. 6 ust. 1 pkt. a i c ogólnego rozporządzenia o
                ochronie danych osobowych z 27 kwietnia 2016 r. (RODO).
                Przysługuje Pani/ Panu prawo dostępu do treści swoich danych
                osobowych oraz prawo ich sprostowania, usunięcia, ograniczenia
                przetwarzania, prawo do przenoszenia danych, prawo do wniesienia
                sprzeciwu, prawo do cofnięcia zgody na ich przetwarzanie w
                dowolnym momencie bez wpływu na zgodność z prawem przetwarzania,
                którego dokonano na podstawie zgody przed jej cofnięciem.
                Przysługuje Pani/Panu prawo wniesienia skargi na przetwarzanie
                danych do Prezesa Urzędu Ochrony Danych Osobowych. Dane osobowe
                będą przetwarzane do momentu zakończenia prowadzenia rekrutacji,
                nie dłużej jednak niż przez trzy miesiące od dnia przesłania CV.
                Za zgodą kandydatów w okresie 1 roku od zakończenia procesu
                rekrutacyjnego dane osobowe będą przetwarzane w kolejnych
                rekrutacjach prowadzonych przez administratora danych osobowych.
                Dane osobowe kandydatów nie będą podlegały zautomatyzowanemu
                przetwarzaniu.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default AccountSpecialist;
